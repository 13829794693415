import React from "react";
import "./Fleets.scss";
import rr_logo from "../../assets/images/fleets/luxury/rr-logo.jpeg"
import bmw from "../../assets/images/fleets/luxury/BMW-logo.jpeg"
import benz from "../../assets/images/fleets/luxury/mercedes-logo.jpeg"
import jeep from "../../assets/images/fleets/luxury/jeep-logo.jpeg"
import porsche from "../../assets/images/fleets/luxury/porsche-logo.jpeg"
import r_rover from "../../assets/images/fleets/luxury/land-rover-logo.jpeg"
import volvo from "../../assets/images/fleets/luxury/vovlo-logo.jpeg"
import bentley from "../../assets/images/fleets/luxury/bentley-logo.jpeg"
import audi from "../../assets/images/fleets/luxury/audi-logo.jpeg"




const Luxury = () => {
  return (
    <div>
      <div className="sector">
      <div className="logo-title" >
      <img src={rr_logo}  alt="rr-logo" style={{width:"100px"}}/>
      {/* <h3>Rolls Royce</h3> */}
      </div>
        <div className="sector-box">
          <div className="rr-phantom"></div>
          <h2>Rolls Royce Phantom</h2>
        </div>
        <div className="sector-box">
          <div className="rr-ghost"></div>
          <h2>Rolls Royce Ghost</h2>
        </div>
        <div className="sector-box">
          <div className="rr-wraith"></div>
          <h2>Rolls Royce Wraith</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={bmw}  alt="bmw-logo" style={{width:"100px"}}/>
      {/* <h3>BMW</h3> */}
      </div>
        <div className="sector-box">
          <div className="bmw-x3"></div>
          <h2>BMW X3</h2>
        </div>
        <div className="sector-box">
          <div className="bmw-x5"></div>
          <h2>BMW X5</h2>
        </div>
        <div className="sector-box">
          <div className="bmw-x7"></div>
          <h2>BMW X7</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={benz}  alt="benz-logo" style={{width:"100px"}}/>
      {/* <h3>Benz</h3> */}
      </div>
        <div className="sector-box">
          <div className="benz-a"></div>
          <h2>Benz A Class</h2>
        </div>
        <div className="sector-box">
          <div className="benz-c"></div>
          <h2>Benz C Class</h2>
        </div>
        <div className="sector-box">
          <div className="benz-cls"></div>
          <h2>Benz Cls Class</h2>
        </div>
        <div className="sector-box">
          <div className="benz-v"></div>
          <h2>Benz V Class</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={r_rover}  alt="range-rover-logo" style={{width:"100px"}}/>
      {/* <h3>Range Rover</h3> */}
      </div>
        <div className="sector-box">
          <div className="rng-rover"></div>
          <h2>Range Rover</h2>
        </div>
        <div className="sector-box">
          <div className="rng-rover-velar"></div>
          <h2>Range Rover Velar</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={audi}  alt="audi-logo" style={{width:"100px"}}/>
      {/* <h3>Audi</h3> */}
      </div>
        <div className="sector-box">
          <div className="a-q8"></div>
          <h2>Audi Q8</h2>
        </div>
        <div className="sector-box">
          <div className="a-q7"></div>
          <h2>Audi Q7</h2>
        </div>
        <div className="sector-box">
          <div className="a-q3"></div>
          <h2>Audi Q3</h2>
        </div>
        <div className="sector-box">
          <div className="a-a8"></div>
          <h2>Audi A8</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={bentley}  alt="bentley-logo" style={{width:"100px"}}/>
      {/* <h3>Bentley</h3> */}
      </div>
        <div className="sector-box">
          <div className="b-bentayga"></div>
          <h2>Bentley Bentayga</h2>
        </div>
        <div className="sector-box">
          <div className="b-mulsanne"></div>
          <h2>Bentley Mulsanne</h2>
        </div>
        <div className="sector-box">
          <div className="b-fly"></div>
          <h2>Bentley Flying Spur</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={porsche}  alt="porsche-logo" style={{width:"100px"}}/>
      {/* <h3>Porsche</h3> */}
      </div>
        <div className="sector-box">
          <div className="p-cayenne"></div>
          <h2>Porsche Cayenne</h2>
        </div>
        <div className="sector-box">
          <div className="p-boxster"></div>
          <h2>Porsche Boxster</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={volvo}  alt="volvo-logo" style={{width:"100px"}}/>
      {/* <h3>Volvo</h3> */}
      </div>
        <div className="sector-box">
          <div className="volvo-xc40"></div>
          <h2>Volvo XC40</h2>
        </div>
        <div className="sector-box">
          <div className="volvo-xc90"></div>
          <h2>Volvo XC90</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title">
      <img src={jeep}  alt="jeep" style={{width:"100px"}}/>
      {/* <h3>Jeep</h3> */}
      </div>
        <div className="sector-box">
          <div className="jeep"></div>
          <h2>Jeep Compass</h2>
        </div>
      </div>
    </div>
  );
};

export default Luxury;
