import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import "./Footer.scss";
import mainlogo from "../../src/assets/images/logo/starcars-logo.png";

export default class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="container mx-auto">
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="about-company">
                <div className="logo">
                  <img src={mainlogo} alt="Logo" />{" "}
                </div>
                <div className="title">
                  <p>
                    Starcars india is the only digital platform for for hiring
                    selfdrive and chauffeur driven cars. Starcars today has over
                    2 crore plus fleet of certified chauffeurs in the platform
                    and growing everyday.
                  </p>
                </div>
              </div>
            </Col>

            {/* <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="quick-links">
                <h3>UseFul Link</h3>
                <ul>
                  <li>
                    <Link to="">Home</Link>
                  </li>
                  <li>
                    <Link to="">About Us</Link>
                  </li>
                  <li>
                    <Link>Our Team</Link>
                  </li>
                  <li>
                    <Link>Our Blog</Link>
                  </li>
                  <li>
                    <Link to="">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </Col> */}
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="social-media quick-links">
                <h3>Follow Us</h3>
                <ul>
                  <li>
                    <Link>
                      <i className="flaticon-facebook-3" />
                      -facebook
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-instagram" /> -instagram
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-twitter" /> - twitter
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-youtube-1" /> -youtube
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 6 }}
            >
              <div className="quick-links">
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <Link>Careers</Link>
                  </li>
                  <li>
                    <Link to="/termsandconditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link>Copyright Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
            {/* <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="Details-Section">
                <div className="Detail">
                  <h1>Contact Us</h1>
                  <div className="Details Address ">
                    <p>
                    <span style={{fontWeight:"bold"}}>Address -</span> 4th Floor, 402 - 406 Prestige Omega,<br></br>EPIP
                      Whitefield Bangalore- 560066
                    </p>
                  </div>
                  <div className="Details Call">
                    <p><span style={{fontWeight:"bold"}}>Call-Us -</span>(toll free)</p>
                  </div>
                  <div className="Details Mail">
                    <p><span style={{fontWeight:"bold"}}>Mail Us -</span>info@buildraudits.com</p>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
        </div>
        <div className="states">
          <p>
            Bengaluru | Hyderabad | Chennai | Cochin | Mumbai | Pune | Goa |
            Gurgaon | Chandigarh | Jaipur
          </p>
        </div>
        <div className="copyright">
          <p>Copyright © 2022 starcarsindia. All Rights Reserved.</p>
        </div>
      </div>
    );
  }
}
