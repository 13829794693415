import React, { Component } from 'react'
// import { Select } from 'antd';

class Dropdwn extends Component{
  constructor(){
      super();
      this.state={
          DDL1:[],
          DDL2:[],
          DDL3:[],
          selectddl:'',
      };
  }

    componentDidMount(){
        this.setState({
            DDL1:[
                {name:'Residential',DDL2:['Studio','1BHK','2BHK','3BHK','4BHK','Pent House','Villas','Villament','Row Houses','Loft','Boutique Apartments Residence','Serviced Residences','Boutique Residences','Hotel Residences','Luxury Residences','Bungalows']},
                {name:'Commercial',DDL2:['Business Centers','Offices','Co-working Offices','Serviced Offices','IT Parks','Business Park','Industrial Parks','Auto Parks','Food Parks','R and D Centers','E-com Parks']},
                {name:'Hospitality',DDL2:['7 Star Hotels','5 Star Hotels','3 Star Hotels','2 Star Hotels','1 star Hotels','Boutique Hotels','Branded Hotels','Resorts','Stays']},
                {name:'Healthcare',DDL2:['5 Start Hospitals','Multispecialty Hospitals','Super Specialty Hospitals','Diagnostic Centers','Pharma Centers']},
                {name:'Retail',DDL2:['Malls','Showrooms','Food Courts','Super Markets','Food Markets','Stores']},
                {name:'Luxury Retail',DDL2:['Jewel Souks','Boutiques','Lounges','Bars and Pubs']},
                {name:'Marine',DDL2:['Ports','Yacht Hubs']},
                {name:'Aviation',DDL2:['International Airports','Domestic Airports','Small Airports','Private Jet Lounges','Retail Space at Airports']},
            ]
        })
    }

    selectChange(e){
        this.setState({selectddl:e.target.value});
        this.setState({DDL2:this.state.DDL1.find(x => x.name===e.target.value).DDL2});
        this.setState({DDL3:this.state.DDL2.find(x => x.name===e.target.value).DDL3});
    }

    render(){
        return(
            <div>
             <select value={this.state.selectddl} onChange={this.selectChange.bind(this)}>
                 <option>--Select sector----</option>
                 {this.state.DDL1.map(x =>{
                     return<option>{x.name}</option>
                 })}
             </select>
             <select style={{marginTop:"10px"}}>
                 <option selected disabled>
                     ---Select--
                 </option>
                 {this.state.DDL2.map(x=>{
                     return<option>{x}</option>
                 })}
             </select>
            </div>

        );
    }
}

export default Dropdwn;

// import React from "react";

// class Dropdwn extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       countries: [],
//       states: [],
//       cities: [],
//       selectedCountry: "Country",
//       selectedState: "State",
//     };
//     this.changeCountry = this.changeCountry.bind(this);
//     this.changeState = this.changeState.bind(this);
//   }

//   componentDidMount() {
//     //https://reactjs.org/docs/react-component.html#componentdidmount
//     this.setState({
//       countries: [
//         {
//           name: "Philippines",
//           states: [
//             {
//               name: "Central Luzon",
//               cities: ["Angeles City", "Olongapo", "San Fernando"],
//             },
//             { name: "NCR", cities: ["Pasay City", "Makati", "Marikina"] },
//           ],
//         },
//         {
//           name: "United States of America",
//           states: [
//             {
//               name: "California",
//               cities: ["Sacramento", "Los Angeles", "Bakersfield", "Carson"],
//             },
//             { name: "Florida", cities: ["Tallahassee", "Jacksonville"] },
//             { name: "Illinois", cities: ["Springfield", "Chicago"] },
//             { name: "New Jersey", cities: ["Trenton", "Newark"] },
//           ],
//         },
//       ],
//     });
//   }

//   changeCountry(event) {
//     this.setState({ selectedCountry: event.target.value });
//     this.setState({
//       states: this.state.countries.find(
//         (cntry) => cntry.name === event.target.value
//       ).states,
//     });
//   }

//   changeState(event) {
//     this.setState({ selectedState: event.target.value });
//     const stats = this.state.countries.find(
//       (cntry) => cntry.name === this.state.selectedCountry
//     ).states;
//     this.setState({
//       cities: stats.find((stat) => stat.name === event.target.value).cities,
//     });
//   }

//   render() {
//     return (
//       <div>
//         <select
//           placeholder="Country"
//           value={this.state.selectedCountry}
//           onChange={this.changeCountry}
//           style={{ width: "220px", marginRight: "16px"}}
//     >
//           <option>Country</option>
//           {this.state.countries.map((e, key) => {
//             return <option key={key}>{e.name}</option>;
//           })}
//         </select>

//         <select
//           placeholder="State"
//           value={this.state.selectedState}
//           onChange={this.changeState}
//           style={{ width: "220px", marginRight: "16px" }}
//           className="select" >
//           <option>State</option>
//           {this.state.states.map((e, key) => {
//             return <option key={key}>{e.name}</option>;
//           })}
//         </select>

//         <select
//           placeholder="City"
//           style={{ width: "220px", marginRight: "16px" }}
//           className="select"  >
//           <option>City</option>
//           {this.state.cities.map((e, key) => {
//             return <option key={key}>{e}</option>;
//           })}
//         </select>
//       </div>
//     );
//   }
// }

// export default Dropdwn;
