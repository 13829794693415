import React from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  Select,
  TimePicker,
} from "antd";
import "./BookingForm.scss";
import Dropdwn from "../BookingForm/DropDown";

const BookingForm = () => {
  const [form] = Form.useForm();

  function onChange(time, timeString) {
    console.log(time, timeString);
  }

  // const onFormLayoutChange = ({ layout }) => {
  //   setFormLayout(layout);
  // };

  // const formItemLayout =
  //   formLayout === 'horizontal'
  //     ? {
  //         labelCol: {
  //           span: 4,
  //         },
  //         wrapperCol: {
  //           span: 14,
  //         },
  //       }
  //     : null;
  // const buttonItemLayout =
  //   formLayout === 'horizontal'
  //     ? {
  //         wrapperCol: {
  //           span: 14,
  //           offset: 4,
  //         },
  //       }
  //     : null;
  return (
    <div className="b-form">
      <h1>Booking Form</h1>
      <Form
        // {...formItemLayout}
        layout={"inline"}
        form={form}
        initialValues={{
          layout: "inline",
        }}
        // onValuesChange={onFormLayoutChange}
     className="form" >
        {/* <Form.Item label="Form Layout" name="layout">
          <Radio.Group value={formLayout}>
            <Radio.Button value="horizontal">Horizontal</Radio.Button>
            <Radio.Button value="vertical">Vertical</Radio.Button>
            <Radio.Button value="inline">Inline</Radio.Button>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item>
          <Input className="input" placeholder="Enter Name" style={{ width: "220px"}} />
        </Form.Item>
        {/* <Form.Item>
          <Input placeholder="Last Name" />
        </Form.Item> */}
        <Form.Item>
          <Input className="input" placeholder="Enter Email id" style={{ width: "220px"}} />
        </Form.Item>
        <Form.Item>
          <Input className="input" placeholder="Enter Phone Number" style={{ width: "220px"}} />
        </Form.Item>
        <Form.Item>
          <Input className="input" placeholder="Enter Address" style={{ width: "220px"}}/>
        </Form.Item>
        <div className="radio">
        <input type="radio" id="html" name="fav_language" value="self Drive" ></input>
        <label for="self Drive" className="radion">Self Drive</label>
        <input type="radio" id="css" name="fav_language" value="chauffeur Drive"></input>
        <label for="Chauffeur Drive" className="radion">Chauffeur Drive</label>
        </div>
        {/* <Radio.Button value="horizontal" style={{height: "45px", borderRadius: "5px"}}  >Self Drive</Radio.Button>
          <Radio.Button value="vertical" style={{marginRight:"10px",height: "45px", borderRadius: "5px"}}>chauffeur Drive</Radio.Button> */}

        {/* <Form.Item > */}
        <DatePicker className="input" placeholder="Start Date" style={{marginRight: "16px" }}/>
        <TimePicker
          use12Hours
          format="h:mm:ss A"
          onChange={onChange}
          style={{ width: 220, marginRight: "16px" }}
          placeholder="Start Time"
          className="input"
        />
        {/* </Form.Item> */}
        {/* <Form.Item > */}
        <DatePicker className="input" placeholder="End Date" style={{marginRight: "16px" }}/>
        <TimePicker
          use12Hours
          format="h:mm:ss A"
          onChange={onChange}
          style={{ width: 220, marginRight: "16px" }}
          className="input"
          placeholder="End Time"
        />
        {/* </Form.Item>
      <Form.Item > */}

        <Select
          placeholder="Select Purpose" className="select"
        >
          <Select.Option value="Airport Transfer">
            Airport Transfer
          </Select.Option>
          <Select.Option value="Corporate">Corporate</Select.Option>
          <Select.Option value="OutStation">OutStation</Select.Option>
          <Select.Option value="Wedding">Wedding</Select.Option>
          <Select.Option value="Events">Events</Select.Option>
          <Select.Option value="others">Others</Select.Option>
        </Select>
        {/* </Form.Item>
      <Form.Item > */}
        {/* <Select
          placeholder="Select Fleets"
          className="input"
          style={{ width: "160px", marginRight: "10px" }}
        >
          <Select.Option value="Cars">Cars</Select.Option>
          <Select.Option value="Suvs">SUVS</Select.Option>
          <Select.Option value="muvs">MUVS</Select.Option>
          <Select.Option value="Luxury">Luxury</Select.Option>
        </Select> */}

        <Dropdwn />
        {/* </Form.Item>
      <Form.Item> */}
        <Button type="Submit" color="s-btn" style={{backgroundColor:"#044cd0", color:"white", height:"45px",width:"100px", borderRadius:"5px" }}>Book Now</Button>
        {/* </Form.Item> */}
      </Form>
    </div>
  );
};

export default BookingForm;
