import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../Screens/LandingPage/Home";
import ContactUs from "../Screens/ContactUs/ContactUs";
import PrivacyPolicy from "../Screens/policy/PrivacyPolicy";
import TermsAndConditions from "../Screens/policy/TermsAndConditions";
import Layout from "../Layout/Layout";
import about from "../Screens/about/about";
import SigninSignup from "../Screens/SigninSignup/SigninSignup";
import OurFleets from "../Screens/Our_fleets/OurFleets";
import BookingForm from "../Screens/BookingForm/BookingForm";
import S_Partner from "../Screens/StarcarsPartner/S_Partner"



class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route exact path={`/about`} component={about} />
          <Route exact path={`/starcarspartner`} component={S_Partner} />
          <Route exact path={`/contact`} component={ContactUs} />
          <Route exact path={`/privacypolicy`} component={PrivacyPolicy} />
          <Route exact path={`/termsandconditions`} component={TermsAndConditions}/>
          <Route exact path={`/ourfleets`} component={OurFleets} />
          <Route exact path={`/bookingform`} component={BookingForm} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
