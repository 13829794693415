import React from "react";
import "./BookingForm.scss"

class Dropdwn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fleets: [],
      brands: [],
      models: [],
      selectedFleet: "Fleet",
      selectedBrand: "Brand",
    };
    this.changeFleet = this.changeFleet.bind(this);
    this.changeBrand = this.changeBrand.bind(this);
  }

  componentDidMount() {
    //https://reactjs.org/docs/react-component.html#componentdidmount
    this.setState({
      fleets: [
        {
          name: "Cars",
          brands: [
            {
              name: "Skoda",
              models: ["Skoda Rapid", "Skoda Superb", "Skoda Laura", "Skoda Octavia"],
            },
            { name: "Nissan", 
            models: ["Nissan Sunny", "Nissan Teana", "Nissan Terrano"] },
            { name: "Honda", 
            models: ["Honda City", "Honda City IVTEC", "Honda Amaz", "Honda City ZX"] },
            { name: "Hyundai", 
            models: ["i20 Active", "Grand i10 Niose", "Hyundai Verna"] },
            { name: "Suzuki", 
            models: ["Ciaz"] },
          ],
        },
        {
          name: "SUVS",
          brands: [
            {
              name: "Toyota",
              models: ["Toyota Fortuner"],
            },
            { name: "Skoda", models: ["Skoda Kushaq"] },
            { name: "Hyundai", models: ["Creta"] },
            { name: "Mahindra", models: ["Mahindra SUV 700"] },
            { name: "MG", models: ["MG Hector"] },
            { name: "Kia", models: ["Kia Seltos"] },
          ],
        },
        {
          name: "MUVS",
          brands: [
            {
              name: "Toyota",
              models: ["Toyota Innova", "Toyota Commute", "Toyota vellfire"],
            },
            { name: "Kia", models: ["Kia Carnival"] },
            { name: "Mahindra", models: ["Mahindra Marazzo"] },
            { name: "Suzuki", models: ["Ertiga"] },
          ],
        },
        {
          name: "Luxury",
          brands: [
            {
              name: "Rolls Royce",
              models: ["Rolls Royce Phantom", "Rolls Royce Ghost", "Rolls Royce Wraith"],
            },
            { name: "BMW", models: ["BMW X3", "BMW X5", "BMW X7"] },
            { name: "Mercedes Benz", models: ["Benz A Class", "Benz C Class","Benz Cls Class", "Benz V Class"] },
            { name: "Land Rover", models: ["Range Rover", "Range Rover Velar"] },
            { name: "Audi", models: ["Audi Q8", "Audi Q7", "Audi Q3", "Audi A8"] },
            { name: "Bentley", models: ["Bentley Bentayga", "Bentley Mulsanne", "Bentley Flying Spur"] },
            { name: "Porsche", models: ["porsche Cayene", "Porsche Boxster"] },
            { name: "Volvo", models: ["Volvo XC40", "Volvo XC90"] },
            { name: "Jeep", models: ["Jeep Compass"] },
          ],
        },
      ],
    });
  }

  changeFleet(event) {
    this.setState({ selectedFleet: event.target.value });
    this.setState({
      brands: this.state.fleets.find(
        (flt) => flt.name === event.target.value
      ).brands,
    });
  }

  changeBrand(event) {
    this.setState({ selectedBrand: event.target.value });
    const brnds = this.state.fleets.find(
      (flt) => flt.name === this.state.selectedFleet
    ).brands;
    this.setState({
      models: brnds.find((brnd) => brnd.name === event.target.value).models,
    });
  }

  render() {
    return (
      <div>
        <select
          placeholder="Select Fleets"
          value={this.state.selectedFleet}
          onChange={this.changeFleet}
          style={{ width: "220px", marginRight: "16px"}}
    className="select"  >
          <option>---Select Fleet---</option>
          {this.state.fleets.map((e, key) => {
            return <option key={key}>{e.name}</option>;
          })}
        </select>

        <select
          placeholder="State"
          value={this.state.selectedBrand}
          onChange={this.changeBrand}
          style={{ width: "220px", marginRight: "16px" }}
          className="select" >
          <option>---Select Brand---</option>
          {this.state.brands.map((e, key) => {
            return <option key={key}>{e.name}</option>;
          })}
        </select>

        <select
          placeholder="City"
          style={{ width: "220px", marginRight: "16px" }}
          className="select"  >
          <option>---Select Model---</option>
          {this.state.models.map((e, key) => {
            return <option key={key}>{e}</option>;
          })}
        </select>
      </div>
    );
  }
}

export default Dropdwn;
