import React from "react";
import oyo from "../../assets/images/partners/oyo.png";
import treebo from "../../assets/images/partners/treebo.png";
import taj from "../../assets/images/partners/taj.png";
import jw from "../../assets/images/partners/jw.png";
import oberoi from "../../assets/images/partners/oberoi.png";
import jets from "../../assets/images/partners/firstjets.png";
import ww from "../../assets/images/partners/ww.png";
import hilton from "../../assets/images/partners/hilton.jpg"
import helifly from "../../assets/images/partners/helifly.png"
import "./Partners.scss";

const Partners = () => {
  return (
    <React.Fragment>
    <div className="partners">
      <div className="ptrn-title">
        <h1>Our Partners</h1>
      </div>
      <section id="partners">
        <div className="ptrn p-1">
          <img src={oyo} alt="keson" />
        </div>
        <div className="ptrn p-2">
          <img src={treebo} alt="leica" />
        </div>
        <div className="ptrn p-3">
          <img src={taj} alt="bosch" />
        </div>
        <div className="ptrn p-4">
          <img src={jw} alt="technidea" />
        </div>
        <div className="ptrn p-5">
          <img src={oberoi} alt="topcon" />
        </div>
        <div className="ptrn p-6">
          <img src={jets} alt="ele" />
        </div>
        <div className="ptrn p-7">
          <img src={ww} alt="sgs" />
        </div>
        <div className="ptrn p-8">
          <img src={hilton} alt="plumbr" />
        </div>
        <div className="ptrn p-9">
          <img src={helifly} alt="mechman" />
        </div>
      </section>
      </div>
    </React.Fragment>
  );
};

export default Partners;
