import React from "react";
import { Card } from "antd";
import { Row, Col } from "antd";
import sedan from "../../assets/images/star-partner/sedan.jpg";
import ev from "../../assets/images/star-partner/ev1.jpg";
import luxury from "../../assets/images/star-partner/rr.jpg";
import "./S_Partner.scss";

import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import PartnersForm from "./PartnersForm";

const { Meta } = Card;

const S_Partner = () => {
  return (
    <div>
      <section className="partner-banner">
        <div className="container mx-auto">
          <h1>Starcars Partner</h1>
          <p>
              Share your car and earn upto ₹80,000 per month
            </p>
        </div>
      </section>
      <div className="container mx-auto list">
        <Row align="center" style={{ textAlign: "center" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 7 }}
          className="col">
            <Card
              // hoverable
              style={{ width: "350px", height: "330px" }}
              cover={
                <img alt="example" src={sedan} width="400px" height="200px" />
              }
              // actions={[<h1>Join Us</h1>]}
              className="cl" >
              <Meta
                title="Cars (XUV 500, Creta, Skoda Kushaq)"
                description="."
              />
              <h3 style={{ color: "red" }}>Earn ₹35,000/month</h3>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 7 }}
            className="col">
            <Card
              // hoverable
              style={{ width: "350px", height: "330px" }}
              cover={
                <img alt="example" src={ev} width="400px" height="200px" />
              }
              // actions={[<h1>Join Us</h1>]}
              className="cl" >
              <Meta title="EV" description="." />
              <h3 style={{ color: "red" }}>Earn ₹25,000/month</h3>
            </Card>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 7 }}
            className="col">
            <Card
              // hoverable
              style={{ width: "350px", height: "330px" }}
              cover={
                <img alt="example" src={luxury} width="400px" height="200px" />
              }
              // actions={[<p><a href="#join">Back to menu</a></p> ]}
              className="cl">
              <Meta title="Luxury" description="." />
              <h3 style={{ color: "red" }}>Earn ₹80,000/month</h3>
            </Card>
          </Col>
        </Row>
      </div>
      <section>
      <PartnersForm  />
      </section>
      <div className=" benifits" id="join">
        <div>
          <h1>Benefits For Partners</h1>
        </div>
        <ul style={{ listStyle: "none"}}>
          <li>
            <span style={{ color: "red" }}> ✔</span> Car care including wash and dents.{" "}
          </li>
          <li>
            <span style={{ color: "red" }}> ✔</span> Flexibility sharing.{" "}
          </li>
          <li>
            <span style={{ color: "red" }}> ✔</span> Contactless sharing. {" "}
          </li>
          <li>
            <span style={{ color: "red" }}> ✔</span> Free sanitization and car health check up before dispatch.
          </li>
          <li>
            <span style={{ color: "red" }}> ✔</span> 24x7 customer care support.
          </li>
        </ul>
      </div>
      
    </div>
  );
};

export default S_Partner;
