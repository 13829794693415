import React from "react";
import "./Home.scss";
import mainlogo from "../../assets/images/about.jpg";
import { Row, Col } from "antd";
import HomeSlider from "./HomeSlider/HomeSlider";
import { CmSectors } from "../Cm_sectors/CmSectors";
import OurFleets from "../Our_fleets/OurFleets";
import Partners from "../Partners/Partners";
import BookingForm from "../BookingForm/BookingForm";


export default class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* main slider starts */}
        <HomeSlider />
        {/* main slider ends */}

        {/* About Us  */}
        <section id="about">
          <div>
            <Row
              gutter={[
                { xs: 8, sm: 20, md: 30, lg: 40 },
                { xs: 8, sm: 16, md: 10, lg: 10 },
              ]}
            >
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 16 }}
                lg={{ span: 12 }}
                style={{ paddingLeft: "40px" }}
                align="center"
              >
                <p style={{ color: "blue" }}>About Us</p>
                <h1 style={{ fontSize: "35px", color: "#bc2d2e",fontFamily:"serif" }}>
                  STARCARS INDIA
                </h1>
                <p
                  style={{
                    fontSize: "24px",
                    fontFamily:"-moz-initial",
                    color: "#606060",
                  }}
               className="aboutp" >
                  Starcars india is the only digital platform for hiring
                  selfdrive and chauffeur driven cars. Starcars today has over 2
                  crore plus fleet of certified chauffeurs in the platform and
                  growing everyday. Starcars today offers service even at the
                  remotest part of india. Star cars team only source the best
                  and safe cars for our client to drive. Star cars boasts of
                  sedans like Skoda, Nissan, Hyundai, Honda and much more.
                  Starcars also has luxury range like Mercedes, BMW, Jaguar,
                  Range Rover, Maserati and Rolls-Royce.
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 12 }}
                align="left"
              >
                <img className="img"  src={mainlogo} alt="star cars" />
              </Col>
            </Row>
          </div>
        </section>

        <CmSectors />
        <BookingForm />
        <OurFleets />
        <Partners />
        <div className="info">
          <h1>STARCARS INDIA <span style={{float:"right"}}>info@starcarsindia.co.in</span></h1>
        </div>
      </React.Fragment>
    );
  }
}
