import React from "react";
import { withRouter } from "react-router";
import Slider from "react-slick";
import "./HomeSlider.scss";
import { Row, Col } from "antd";
import mainlogo from "../../../assets/images/logo/starcars-main-logo.png";
import evolve from "../../../assets/images/partners/evolveback.png";
import tamara from "../../../assets/images/partners/tamara.png";
import taj from "../../../assets/images/partners/taj.png";
import wilderness from "../../../assets/images/partners/wilderness.png";
import montrose from "../../../assets/images/partners/Montrose.png";
import vythiri from "../../../assets/images/partners/vythiri.png";
import windflower from "../../../assets/images/partners/windflower.png";
import morickap from "../../../assets/images/partners/morickap.png";
import vayalveedu from "../../../assets/images/partners/vayalveedu.png";
import dukes from "../../../assets/images/partners/Dukes.png";
import zarasresort from "../../../assets/images/partners/zarasresort.png";
import valleyvista from "../../../assets/images/partners/valleyvista.png";
import savoy from "../../../assets/images/partners/Savoy.png";
import monarch from "../../../assets/images/partners/monarch.png";
import w from "../../../assets/images/partners/wgoa.png";
import leela from "../../../assets/images/partners/Leela.png";
import itc from "../../../assets/images/partners/ITC.jpg";
import grandhyatt from "../../../assets/images/partners/grand_hyatt.jpg";
import taje from "../../../assets/images/partners/taje.jpg";
import anantmaya from "../../../assets/images/partners/Anantmaya.png";
import royal from "../../../assets/images/partners/royalpark.png";
import himalayan from "../../../assets/images/partners/himalayan.png";
import moniker from "../../../assets/images/partners/moniker.jpg";
import piccadily from "../../../assets/images/partners/Piccadily.png";
import radisson from "../../../assets/images/partners/radisson.png";
import lalit from "../../../assets/images/partners/lalit.jpg";
import vivanta from "../../../assets/images/partners/vivanta.png";
import sheraton from "../../../assets/images/partners/Sheraton.png";
import hilton from "../../../assets/images/partners/hilton.jpg";
import oberoi from "../../../assets/images/partners/oberoi.png";
import holiday from "../../../assets/images/partners/holiday.jpg";
import devrathan from "../../../assets/images/partners/devratan.png";
import rambagh from "../../../assets/images/partners/rambagh.jpg";
import lilly from "../../../assets/images/partners/lilly.jpg"

const Mainslidersettings = {
  arrows: false,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
};

class HomeSlider extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <!--Main Slider section starts--> */}
        <div className="starcars-banner">
          <div className="starcars-banner-center">
            <div className="slider-content">
              {/* <!--Slider Item starts--> */}
              <Slider {...Mainslidersettings}>
                <div className="item selfdrive">
                  <Row>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 20 }}
                      md={{ span: 20 }}
                      lg={{ span: 24 }}
                    >
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption s1c">
                          {/* <img src={mainlogo} alt="logo" /> */}
                            <h2>Self Drive</h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item chauffeur">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          {/* <img src={mainlogo} alt="logo" /> */}
                            <h2>Chauffeur Drive</h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item airport">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          {/* <img src={mainlogo} alt="logo" /> */}
                            <h2>Airport Transfers</h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}

                <div className="item corporate">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          {/* <img src={mainlogo} alt="logo" /> */}
                            <h2>Corporate</h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item outstation">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          {/* <img src={mainlogo} alt="logo" /> */}
                            <h2>Outstation</h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item coorg">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" /> 
                            <h2>Coorg</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={taj} alt="taj" />
                            </div>
                            <div className="ptrn">
                              <img src={evolve} alt="Evolve back" />
                            </div>
                            <div className="ptrn">
                              <img src={tamara} alt="tamara" />
                            </div>
                            <div className="ptrn">
                              <img src={wilderness} alt="wilderness" />
                            </div>
                            <div className="ptrn">
                              <img src={montrose} alt="montrose" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item wayanad">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" /> 
                            <h2> Wayanad</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={vythiri} alt="vythiri" />
                            </div>
                            <div className="ptrn">
                              <img src={windflower} alt="windflowe" />
                            </div>
                            <div className="ptrn">
                              <img src={morickap} alt="morickap" />
                            </div>
                            <div className="ptrn">
                              <img src={vayalveedu} alt="vayal veedu" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item ooty">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" />
                            <h2> Ooty</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={savoy} alt="savoy" />
                            </div>
                            <div className="ptrn">
                              <img src={monarch} alt="monarch" />
                            </div>
                            <div className="ptrn">
                              <img src={lilly} alt="lilly" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item kandala">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" />
                            <h2> Kandala</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={dukes} alt="dukes retreat" />
                            </div>
                            <div className="ptrn">
                              <img src={zarasresort} alt="zaras resort" />
                            </div>
                            <div className="ptrn">
                              <img src={valleyvista} alt="valleyvista" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item goa">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" />
                            <h2>Goa</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={w} alt="w hotel" />
                            </div>
                            <div className="ptrn">
                              <img src={leela} alt="leela" />
                            </div>
                            <div className="ptrn">
                              <img src={itc} alt="ITC Grand" />
                            </div>
                            <div className="ptrn">
                              <img src={grandhyatt} alt="Grand hyatt" />
                            </div>
                            <div className="ptrn">
                              <img src={taje} alt="taj Exotica" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item manali">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" />
                            <h2>Manali</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={anantmaya} alt="anantmaya" />
                            </div>
                            <div className="ptrn">
                              <img src={royal} alt="royal" />
                            </div>
                            <div className="ptrn">
                              <img src={himalayan} alt="himalayan" />
                            </div>
                            <div className="ptrn">
                              <img src={moniker} alt="moniker" />
                            </div>
                            <div className="ptrn">
                              <img src={piccadily} alt="piccadily" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item  kashmir">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" />
                            <h2>Kashmir</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={radisson} alt="radisson" />
                            </div>
                            <div className="ptrn">
                              <img src={lalit} alt="lalit" />
                            </div>
                            <div className="ptrn">
                              <img src={vivanta} alt="vivanta" />
                            </div>
                            <div className="ptrn">
                              <img src={sheraton} alt="sheraton" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
                <div className="item jaipur">
                  <Row justify="center">
                    <Col span={24}>
                      <div className="content">
                        <div className="center-content">
                          <div className="slider-caption">
                          <img src={mainlogo} alt="logo" />
                            <h2>Jaipur</h2>
                            <p>In partnership with</p>
                          </div>
                          <section id="partners">
                            <div className="ptrn">
                              <img src={rambagh} alt="rambagh" />
                            </div>
                            <div className="ptrn">
                              <img src={hilton} alt="hilton" />
                            </div>
                            <div className="ptrn">
                              <img src={oberoi} alt="oberoi" />
                            </div>
                            <div className="ptrn">
                              <img src={devrathan} alt="devrathan" />
                            </div>
                            <div className="ptrn">
                              <img src={holiday} alt="holiday" />
                            </div>
                          </section>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <!--Slider Item ends--> */}
              </Slider>
            </div>
          </div>
        </div>
        {/* <!--Main Slider section ends--> */}
      </React.Fragment>
    );
  }
}
export default withRouter(HomeSlider);
