import React from "react";
import { withRouter } from "react-router";
import { Row, Col } from "antd";
import "./about.scss";
import mainlogo from "../../assets/images/about.jpg";

class AboutUs extends React.Component {
  render() {
    return (
      <React.Fragment>
         <section className="partner-banner">
        <div className="container mx-auto">
          <h1>About us</h1>
          <p>
              STARCARS INDIA
            </p>
        </div>
      </section>

        <div className="inner-pages light-bg">
          <div className="container mx-auto">
            <section id="about">
              <div>
                <Row
                  gutter={[
                    { xs: 8, sm: 20, md: 30, lg: 40 },
                    { xs: 8, sm: 16, md: 10, lg: 10 },
                  ]}
                >
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 16 }}
                    lg={{ span: 12 }}
                    align="center"
                  >
                    <p style={{ color: "blue" }}>About Us</p>
                    <h1 style={{ fontSize: "35px", color: "#bc2d2e",fontFamily:"serif" }}>
                      STARCARS INDIA
                    </h1>
                    <p
                      style={{
                        fontSize: "22px",
                        fontFamily: "serif",
                        color: "#606060",
                      }}
                    >
                      Starcars india is the only digital platform for hiring
                      selfdrive and chauffeur driven cars. Starcars today has
                      over 2 crore plus fleet of certified chauffeurs in the
                      platform and growing everyday. Starcars today offers
                      service even at the remotest part of india. Star cars team
                      only source the best and safe cars for our client to
                      drive. Star cars boasts of sedans like Skoda, Nissan,
                      Hyundai, Honda and much more. Starcars also has luxury
                      range like Mercedes, BMW, Jaguar, Range Rover, Maserati
                      and Rolls-Royce.
                    </p>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 12 }}
                    align="left"
                  >
                    <img src={mainlogo} alt="star cars" />
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(AboutUs);
