import React from "react";
import { Tabs } from "antd";
import "./OurFleets.scss"
import Luxury from "./Luxury";
import Cars from "./Cars";
import Suv from "./Suv";
import Muv from "./Muv";

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

class OurFleets extends React.Component {
  render() {
    return (
      <React.Fragment>
      <div className="fleet">
           <h1>Discover Our Fleets</h1>
        <Tabs defaultActiveKey="4" onChange={callback} centered style={{margin:"40px 20px"}}>
          <TabPane tab={<span style={{fontSize:"25px", margin:"40px"}}>Cars</span>} key="1">
            <Cars/>
          </TabPane>
          <TabPane tab={<span style={{fontSize:"25px", margin:"40px"}}>SUVS</span>} key="2">
            <Suv />
          </TabPane>
          <TabPane tab={<span style={{fontSize:"25px", margin:"40px"}}>MUVS</span>} key="3">
           <Muv/>
          </TabPane>
          <TabPane tab={<span style={{fontSize:"25px", margin:"40px"}}>Luxury</span>} key="4">
          <Luxury />
          </TabPane>
        </Tabs>
        </div>
      </React.Fragment>
    );
  }
}
export default OurFleets;
