import React from "react";
import { withRouter } from "react-router";
import InputBox from "../../Components/InputBox/InputBox";
import { Row, Col, Button } from "antd";
import "./Contactus.scss";
import { Input } from "antd";
import Dropdwn from "./Dropdwn";

const { TextArea } = Input;

class ContactUs extends React.Component {
  constructor() {
    super();
    this.state = {
      defaultActiveKey: [],
      mobileNumber: "",
      fullName: "",
      emailId: "",
      phoneNumber: "",
      location:"",
    };
  }

  onChangeText = (value, type) => {
    this.setState({ [type]: value });
  };

  render() {
    return (
      <React.Fragment>
        {/* start - page banners */}
        <section className="page-banners bundles">
          <div className="container mx-auto">
            <h1 style={{ fontSize: "45px" }}>Contact Us</h1>
            <p>
              We'd Love To Hear From You Whether you have a question about
              pricing, need a site vist or anything, our team is ready to answer
              all your questions
            </p>
          </div>
        </section>
        {/* End - page banners */}
        <div className="signin-singup">
          <div className="Contact-head">
            <p>Send Us Message</p>
            <h1 style={{ fontSize: "45px" }}>Contact Us</h1>
          </div>

          <Row gutter={[12, 20]}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              {/* <img src={require('../../assets/images/signin-signup.webp')} alt="Signin Singup" /> */}
              <div className="Details-Section">
                <div className="Detail">
                  <h1>Contact Us</h1>
                  <div className="Details Address ">
                    <h1>Address</h1>
                    <p>
                      4th Floor, 402 - 406 Prestige Omega,<br></br>EPIP
                      Whitefield Bangalore- 560066
                    </p>
                  </div>
                  <div className="Details Call">
                    <h1>Call-Us</h1>
                    <p>(toll free)</p>
                  </div>
                  <div className="Details Mail">
                    <h1>Mail Us</h1>
                    <p>info@buildraudits.com</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              style={{ paddingLeft: "30px" }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              className="Contact-right"
            >
              <div className="title">
                <h1 align="center" style={{ fontSize: "45px" }}>
                  Welcome
                </h1>
                {/* <p>Select Your Location To Help Us Serve You Better!</p> */}
              </div>
              <form>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Full Name"
                    id="fullName"
                    value={this.state.fullName}
                    onChangeText={this.onChangeText}
                    type="text"
                  />
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Email"
                    id="emailId"
                    value={this.state.emailId}
                    onChangeText={this.onChangeText}
                    type="email"
                  />
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Enter Phone Number"
                    id="phoneNumber"
                    value={this.state.phoneNumber}
                    onChangeText={this.onChangeText}
                    type="tel"
                  />
                </div>

                <div className="form-fields">
                  <Dropdwn/>
                </div>
                <div className="form-fields">
                  <InputBox
                    placeholder="Location Prefernce"
                    id="location"
                    value={this.state.location}
                    onChangeText={this.onChangeText}
                    type="text"
                  />
                </div>

                <div className="form-fields">
                  <TextArea rows={4} placeholder="Comments" />
                </div>
                <div className="form-fields">
                  <Button type="primary">Submit</Button>
                </div>
              </form>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(ContactUs);



// import React from 'react';
// import { Form, Input, Button, Radio } from 'antd';

// const ContactUs = () => {
//   return <div>
//               <Form>
//       <Form.Item label="Form Layout" name="layout">
//         <Radio.Group >
//           <Radio.Button value="horizontal">Self Drive</Radio.Button>
//           <Radio.Button value="vertical">Chufferdrive</Radio.Button>
//         </Radio.Group>
//       </Form.Item>
//       <Form.Item label="Field A">
//         <Input placeholder="input placeholder" />
//       </Form.Item>
//       <Form.Item label="Field B">
//         <Input placeholder="input placeholder" />
//       </Form.Item>
//       <Form.Item>
//         <Button type="primary">Submit</Button>
//       </Form.Item>
//     </Form>
//   </div>;
// };

// export default ContactUs;
