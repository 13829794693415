import React from "react";
import {
  Form,
  Input,
  Button,
  Radio,
  DatePicker,
  Select,
  TimePicker,
} from "antd";
import "./S_Partner.scss";


const PartnersForm = () => {
  const [form] = Form.useForm();
  return (
    <div className="p-form">
      <h1>Join Starcars Partner</h1>
      <Form
        // {...formItemLayout}
        layout={"inline"}
        form={form}
        initialValues={{
          layout: "inline",
        }}
       
     className="form" >
        
        <Form.Item>
          <Input className="input" placeholder="Enter Name" style={{ width: "240px"}} />
        </Form.Item>
        
        <Form.Item>
          <Input className="input" placeholder="Enter Email id" style={{ width: "240px"}} />
        </Form.Item>
        <Form.Item>
          <Input className="input" placeholder="Enter Phone Number" style={{ width: "240px"}} />
        </Form.Item>
        <Form.Item>
          <Input className="input" placeholder="Enter Address" style={{ width: "240px"}}/>
        </Form.Item>
        {/* <div className="radio">
        <input type="radio" id="html" name="fav_language" value="self Drive" ></input>
        <label for="self Drive" className="radion">Self Drive</label>
        <input type="radio" id="css" name="fav_language" value="chauffeur Drive"></input>
        <label for="Chauffeur Drive" className="radion">Chauffeur Drive</label>
        </div> */}

        {/* <Select
          placeholder="Select Purpose" className="select"
        >
          <Select.Option value="Airport Transfer">
            Airport Transfer
          </Select.Option>
          <Select.Option value="Corporate">Corporate</Select.Option>
          <Select.Option value="OutStation">OutStation</Select.Option>
          <Select.Option value="Wedding">Wedding</Select.Option>
          <Select.Option value="Events">Events</Select.Option>
          <Select.Option value="others">Others</Select.Option>
        </Select> */}
       
        <Select
          placeholder="Select Fleets"
          className="select" 
        >
          <Select.Option value="Cars">Cars</Select.Option>
          <Select.Option value="EV">EV</Select.Option>
          <Select.Option value="Luxury">Luxury</Select.Option>
        </Select>

        <Form.Item>
          <Input className="input" placeholder="Brand Name" style={{ width: "240px"}} />
        </Form.Item>

        <Form.Item>
          <Input className="input" placeholder="Model Name" style={{ width: "240px"}} />
        </Form.Item>


        <Button type="Submit" color="s-btn" style={{backgroundColor:"#044cd0", color:"white", height:"45px",width:"100px", borderRadius:"5px" }}>Join Now</Button>
        
      </Form>
    </div>
  );
};

export default PartnersForm;
