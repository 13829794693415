import React from 'react';
import "./Fleets.scss";

const Muv = () => {
  return <div>
      <div className="sector">
      <h3>MUVS</h3>
        <div className="sector-box">
          <div className="t-innova"></div>
          <h2>Toyota Innova</h2>
        </div>
        <div className="sector-box">
          <div className="t-commuter"></div>
          <h2>Toyota Commuter</h2>
        </div>
        <div className="sector-box">
          <div className="kia"></div>
          <h2>Kia Carnival</h2>
        </div>
        <div className="sector-box">
          <div className="m-marazzo"></div>
          <h2>Mahindra Marazzo</h2>
        </div>
        <div className="sector-box" style={{visibility:"hidden"}}>
          <div className="m-marazzo"></div>
          <h2>Mahindra Marazzo</h2>
        </div>
        <div className="sector-box">
          <div className="t-vellfire"></div>
          <h2>Toyota Vellfire</h2>
        </div>
        <div className="sector-box">
          <div className="ertiga"></div>
          <h2>Ertiga</h2>
        </div>
      </div>
  </div>;
};

export default Muv;
