import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row,  Col, } from "antd";
import mainlogo from "../assets/images/logo/starcars-main-logo.png";
import ModalBox from "../Components/ModalBox/ModalBox";
import SigninSignup from "../Screens/SigninSignup/SigninSignup";
import { Select } from "antd";
// import { Menu, Dropdown } from "antd";
// import { DownOutlined } from "@ant-design/icons";
// import {SubMenu} from "antd"

// import {
//   AppstoreOutlined,
// } from "@ant-design/icons";
// const menu = (
//   <Menu>
//   <option value="Andhra Pradesh">Andhra Pradesh</option>
//                         <option value="Andaman and Nicobar Islands">
//                           Andaman and Nicobar Islands
//                         </option>
//                         <option value="Arunachal Pradesh">
//                           Arunachal Pradesh
//                         </option>
//                         <option value="Assam">Assam</option>
//                         <option value="Bihar">Bihar</option>
//                         <option value="Chandigarh">Chandigarh</option>
//                         <option value="Chhattisgarh">Chhattisgarh</option>
//                         <option value="Dadar and Nagar Haveli">
//                           Dadar and Nagar Haveli
//                         </option>
//                         <option value="Daman and Diu">Daman and Diu</option>
//                         <option value="Delhi">Delhi</option>
//                         <option value="Lakshadweep">Lakshadweep</option>
//                         <option value="Puducherry">Puducherry</option>
//                         <option value="Goa">Goa</option>
//                         <option value="Gujarat">Gujarat</option>
//                         <option value="Haryana">Haryana</option>
//                         <option value="Himachal Pradesh">
//                           Himachal Pradesh
//                         </option>
//                         <option value="Jammu and Kashmir">
//                           Jammu and Kashmir
//                         </option>
//                         <option value="Jharkhand">Jharkhand</option>
//                         <option value="Karnataka">Karnataka</option>
//                         <option value="Kerala">Kerala</option>
//                         <option value="Madhya Pradesh">Madhya Pradesh</option>
//                         <option value="Maharashtra">Maharashtra</option>
//                         <option value="Manipur">Manipur</option>
//                         <option value="Meghalaya">Meghalaya</option>
//                         <option value="Mizoram">Mizoram</option>
//                         <option value="Nagaland">Nagaland</option>
//                         <option value="Odisha">Odisha</option>
//                         <option value="Punjab">Punjab</option>
//                         <option value="Rajasthan">Rajasthan</option>
//                         <option value="Sikkim">Sikkim</option>
//                         <option value="Tamil Nadu">Tamil Nadu</option>
//                         <option value="Telangana">Telangana</option>
//                         <option value="Tripura">Tripura</option>
//                         <option value="Uttar Pradesh">Uttar Pradesh</option>
//                         <option value="Uttarakhand">Uttarakhand</option>
//                         <option value="West Bengal">West Bengal</option>

//   </Menu>)

export let setHeader = false;
export default class Header extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      signinsignup: false,
      headerView: false,
      name: "",
    };
  }

  onCancel = () => {
    this.setState({ open: false });
    this.setState({ signinsignup: false });
  };
  render() {
    console.log(this.props);
    return (
      <React.Fragment>
        <div className="cta-header" id="cta-header">
          <Row align="center" style={{ paddingTop: "5px" }}>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
              align="left"
            >
              <div className="book-cars">
                <a href="tel:+91 90352 26890">
                  <i className="flaticon-phone-call" />
                  +91 90352 26890
                </a>
                <span>or</span>
                <a href="mailto:info@starcarsindia.co.in">
                  <i className="flaticon-email-1" />{" "}
                  info@starcarsindia.co.in
                </a>
              </div>
            </Col>
            <Col
              xs={{ span: 24, order: 1 }}
              sm={{ span: 24, order: 1 }}
              md={{ span: 12, order: 1 }}
              lg={{ span: 12, order: 1 }}
              align="right"
            >
              <div className="social-media">
                <ul>
                  <li>
                    <Link>
                      <i className="flaticon-facebook-3" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-twitter" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <i className="flaticon-youtube-1" />
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
        <div className="main-hearder" id="main-hearder">
          <div className="container mx-auto">
            <div className="top-header">
              <Row>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 24, order: 1 }}
                  md={{ span: 4, order: 1 }}
                  lg={{ span: 4, order: 1 }}
                >
                  <div className="left-section">
                    <div className="logo">
                      <Link to="/">
                        <img src={mainlogo} alt="logo" />{" "}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 24, order: 2 }}
                  md={{ span: 17, order: 2 }}
                  lg={{ span: 17, order: 2 }}
                >
                  <div className="bottom-header">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/starcarspartner">Starcars Partner</Link>
                      </li>
                      <li>
                        <Link to="/ourfleets">Discover Our Fleets</Link>
                      </li>
                      <li>
                        <Link to="/bookingform">Booking Form</Link>
                      </li>



                      {/* <li>
                        <Link to="/contact">Contact Us</Link>
                      </li> */}
{/* 
                      <li className="dropdown">
                        <Dropdown overlay={menu}>
                          <a
                            className="ant-dropdown-link"
                            onClick={(e) => e.preventDefault()}
                            href
                          >
                           States <DownOutlined />
                          </a>
                        </Dropdown>
                      </li> */}
                     
                    </ul>
                  </div>
                </Col>
                <Col
                  xs={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 3 }}
                  md={{ span: 3, order: 3 }}
                  lg={{ span: 3, order: 3 }}
                >
                 <Select
                        placeholder="Select State"
                        allowClear
                        style={{ width: "150px", borderRadius: "10px",backgroundColor:"blue", float:"right",marginTop:"15px" }}
                      > 
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Andaman and Nicobar Islands">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Arunachal Pradesh">
                          Arunachal Pradesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadar and Nagar Haveli">
                          Dadar and Nagar Haveli
                        </option>
                        <option value="Daman and Diu">Daman and Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">
                          Himachal Pradesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                      </Select>
                  {/* <Tooltip title="login">
                    <Button
                      style={{
                        backgroundColor: "#2b3990",
                        color: "#ffff",
                        marginTop: "15px",
                        borderRadius: "10px",
                      
                      }}
                      href="/signup"
                      className="search-button"
                      shape="square"
                      // icon={<SearchOutlined />}
                      size="large"
                      onClick="/signup"
                    >
                      <Link to="/signup"> Signin | Signup</Link>
                    </Button>
                  </Tooltip> */}
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* Mobile-Header */}
        {/* <div className="mobile-header" id="mobile-header">
          <div className="container mx-auto">
            <Row>
              <Col
                xs={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
              >
                <div className="left-section">
                  <div className="logo">
                    <Link to="/">
                      <img src={mainlogo} alt="Staging Depot" />{" "}
                    </Link>
                  </div>
                </div>
              </Col>
              <Col
                xs={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                lg={{ span: 12, order: 1 }}
                align="right"
              >
                <Menu
                  id="menu"
                  theme={this.state.theme}
                  onClick={this.handleClick}
                  style={{ width:"150px",position:"relative" }}
                  defaultOpenKeys={["sub1"]}
                  selectedKeys={[this.state.current]}
                  mode="inline"
                >
                  <SubMenu key="sub2" icon={<AppstoreOutlined />} title="Nav-Menu" width="100%">
                    <Menu.Item key="5"><Link to="/">Home</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/about-us">About Us</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/residential">Residential</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/commercial">Commercial</Link></Menu.Item>
                    <Menu.Item key="6"><Link to="/contact">Contact Us</Link></Menu.Item>
                  </SubMenu>
                </Menu>
              </Col>
            </Row>
          </div>
        </div> */}

        <ModalBox
          content="test"
          visible={!!this.state.signinsignup}
          width={900}
          onCancel={this.onCancel}
          footer={null}
          destroyOnClose
        >
          <SigninSignup />
        </ModalBox>
      </React.Fragment>
    );
  }
}
