import React from 'react';
import "./Fleets.scss";
import skoda from "../../assets/images/fleets/cars/skoda-logo.jpeg"
import honda from "../../assets/images/fleets/cars/honda-logo.jpeg"
import hyundai from "../../assets/images/fleets/cars/hyundai-logo.jpeg"
import suzuki from "../../assets/images/fleets/cars/Suzuki-logo.png"
import nissan from "../../assets/images/fleets/cars/nissan-logo.jpeg"

const Cars = () => {
  return <div>
      <div className="sector">
      <div className="logo-title" >
      <img src={skoda}  alt="skoda-logo" style={{width:"100px"}}/>
      {/* <h3>Skoda</h3> */}
      </div>
        <div className="sector-box">
          <div className="s-rapid"></div>
          <h2>Skoda Rapid</h2>
        </div>
        <div className="sector-box">
          <div className="s-superb"></div>
          <h2>Skoda Superb</h2>
        </div>
        <div className="sector-box">
          <div className="s-laura"></div>
          <h2>Skoda laura</h2>
        </div>
        <div className="sector-box">
          <div className="s-octavia"></div>
          <h2>Skoda Octavia</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title" >
      <img src={nissan}  alt="nissan-logo" style={{width:"100px"}}/>
      {/* <h3>Nissan</h3> */}
      </div>
        <div className="sector-box">
          <div className="n-sunny"></div>
          <h2>Nissan Sunny</h2>
        </div>
        <div className="sector-box">
          <div className="n-teana"></div>
          <h2>Nisaan Teana</h2>
        </div>
        <div className="sector-box">
          <div className="n-terrano"></div>
          <h2>Nisaan Terrano</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title" >
      <img src={honda}  alt="honda-logo" style={{width:"100px"}}/>
      {/* <h3>Honda</h3> */}
      </div>
        <div className="sector-box">
          <div className="h-city"></div>
          <h2>Honda City</h2>
        </div>
        <div className="sector-box">
          <div className="h-city-ivtech"></div>
          <h2>Honda City IVTEC</h2>
        </div>
        <div className="sector-box">
          <div className="h-amaz"></div>
          <h2>Honda Amaz</h2>
        </div>
        <div className="sector-box">
          <div className="h-zx"></div>
          <h2>Honda City ZX</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title" >
      <img src={hyundai}  alt="rr-logo" style={{width:"100px"}}/>
      {/* <h3>Hyundai</h3> */}
      </div>
        <div className="sector-box">
          <div className="i20"></div>
          <h2>i20 Active</h2>
        </div>
        <div className="sector-box">
          <div className="i10"></div>
          <h2>Grand i10 niose</h2>
        </div>
        <div className="sector-box">
          <div className="verna"></div>
          <h2>Hyundai Verna</h2>
        </div>
      </div>
      <div className="sector">
      <div className="logo-title" >
      <img src={suzuki}  alt="rr-logo" style={{width:"100px"}}/>
      {/* <h3>Suzuki</h3> */}
      </div>
        <div className="sector-box">
          <div className="ciaz"></div>
          <h2>Ciaz</h2>
        </div>
      </div>
  </div>;
};

export default Cars;
