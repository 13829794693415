import React from 'react';
import "./Fleets.scss";

const Suv = () => {
  return <div>
      <div className="sector">
      <h3>SUVS</h3>
        <div className="sector-box">
          <div className="t-fortuner"></div>
          <h2>Toyota Fortuner</h2>
        </div>
        <div className="sector-box">
          <div className="s-kushaq"></div>
          <h2>Skoda Kushaq</h2>
        </div>
        <div className="sector-box">
          <div className="creta"></div>
          <h2>Creta</h2>
        </div>
        <div className="sector-box">
          <div className="suv-700"></div>
          <h2>Mahindra SUV 700</h2>
        </div>
        <div className="sector-box" style={{visibility: "hidden"}}>
          <div className="suv-700"></div>
          <h2>Mahindra SUV 700</h2>
        </div>
        <div className="sector-box">
          <div className="mg"></div>
          <h2>MG Hector</h2>
        </div>
        <div className="sector-box">
          <div className="k-seltos"></div>
          <h2>Kia Seltos</h2>
        </div>
      </div>
  </div>;
};

export default Suv;
