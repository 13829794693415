import React from "react";
import "./CmSectors.scss";

// const Mainslidersettings = {
//   arrows: false,
//   dots: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 5,
//   slidesToScroll: 1,
//   autoplay: false,
//   autoplaySpeed: 5000,
// };

export const CmSectors = () => {
  return (
    <div className="cmSectors">
      <h1>Our Focus</h1>
      <div className="sector">
        <div className="sector-box">
          <div className="luxury-retail"></div>
          <h2>Self Drive</h2>
        </div>
        <div className="sector-box">
          <div className="hospitality"></div>
          <h2>chauffeur Drive</h2>
        </div>
        <div className="sector-box">
          <div className="health-care"></div>
          <h2>Airport Transfer</h2>
        </div>
        <div className="sector-box">
          <div className="marine"></div>
          <h2>Corporate</h2>
        </div>
        <div className="sector-box">
          <div className="aviation"></div>
          <h2>Outstation</h2>
        </div>
        <div className="sector-box">
          <div className="Weddings"></div>
          <h2>Weddings</h2>
        </div>
        <div className="sector-box">
          <div className="events"></div>
          <h2>Events</h2>
        </div>
      </div>
    </div>
  );
};
